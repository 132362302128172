:root {
  --toastify-toast-width: 420px !important;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

.h-100 {
  height: 100% !important;
}

.h-100vh {
  height: 100vh;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}

.pointer {
  cursor: pointer;
}

.text-underline {
  text-decoration: underline;
}

.uppercase {
  text-transform: uppercase;
}

.transform-none {
  text-transform: none !important;
}

.opacity-5 {
  opacity: 0.5;
}

.mb--5 {
  margin-bottom: -5px;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.lineBreak {
  white-space: pre-line;
}

/**
  Carousel styles
 */
.slick-slide {
  border-radius: 12px;
  box-shadow: 0 2px 0 0 #e3e3e3;
  border: solid 4px #e3e3e3;
}

.scrollBtnClass {
  z-index: 1;
  background-color: 'rgba(255, 255, 255, 0.3)';
  color: '#000';
  box-shadow: 'none';
}

@media only screen and (min-width: 600px) {
  .slick-slide {
    margin: 0 24px;
  }
}

@media only screen and (max-width: 786px) {
  .slick-slide {
    margin: 0 24px;
  }

  .saved-items-image {
    max-width: 100%;
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 12px;
  }

  .plant-care-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
}

.slick-dots {
  bottom: -60px !important;
}

.box-shadow-1 {
  box-shadow: 0 2px 0 0 #e3e3e3;
}

/**
  Fade in
 */

.fade-in {
  animation: fadeIn linear 0.8s;
  -webkit-animation: fadeIn linear 0.8s;
  -moz-animation: fadeIn linear 0.8s;
  -o-animation: fadeIn linear 0.8s;
}

.saved-items-image {
  max-width: 100%;
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 25px;
}

.plant-care-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 24px;
}

.mui-input-class {
  border: none !important;
}

.grow-your-own-title span:nth-of-type(even) {
  color: #d1fe4e;
}

.Toastify__toast {
  border-radius: 22px !important;
  padding: 10px !important;
}

.Toastify__close-button {
  box-shadow: none !important;
  color: #f44174 !important;
  background: #f9efec !important;
  border-radius: 46% 53% 59% 42% / 42% 48% 52% 58% !important;
  padding: 4px !important;
  opacity: 1 !important;
}

.Toastify__toast-body {
  padding: 12px 10px 10px 6px !important;
}

.Toastify__toast-container--bottom-right {
  bottom: 4rem !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.document-container {
  width: 80%;
  margin: auto;
}

.customGridPaddingZero {
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.legend-for-temperature {
  padding-top: 12px !important;
}

/* YourComponent.css */
.right-side-logo-image-container {
  display: flex;
  flex-wrap: wrap;
}

.right-side-logo-image-box {
  width: calc(33.33% - 10px);
}

.right-side-logo-image {
  width: 100%;
  height: auto;
}
.custom-card-height .MuiButtonBase-root {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.custom-card-height .MuiButtonBase-root .MuiCardContent-root {
  width: 100%;
  height: 100%;
  position: relative;
}

/* Media query for screens up to 768px wide */
@media (max-width: 320px) {
  .document-container {
    width: 40%;
  }
}

/* Media query for screens up to 768px wide */
@media (max-width: 768px) {
  .document-container {
    width: 90%;
  }
}

/* Media query for screens 1200px and wider */
@media (min-width: 1200px) {
  .document-container {
    width: 70%;
  }
}

.smplr-wrapper {
  position: relative;
  width: 100%;
  /* max-width: calc(16 / 9 * 75vh); */
  aspect-ratio: 16 / 9;
  background-color: #ecf1f5;
  height: 100%;
  border-radius: 18px;
}

.smplr-embed {
  /* position: relative; */
  width: 100%;
  height: 100%;
}

@media (max-width: 1530px) {
  .smplr-wrapper {
    height: 500px;
  }
}
